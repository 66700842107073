/**
 * Created by avim on 12/27/15.
 */
define([
    'react',
    'lodash',
    'componentsCore',
    'components',
    'backgroundCommon',
    'santa-components',
    'santa-core-utils'
], function (
    React,
    _,
    componentsCore,
    components,
    backgroundCommon,
    santaComponents,
    santaCoreUtils
) {
    'use strict';
    const fillMixin = components.mediaCommon.mediaLogicMixins.fill;

    /**
     * @class components.StripColumnsContainer
     * @extends {core.skinBasedComp}
     */
    const stripColumnsContainer = {
        displayName: 'StripColumnsContainer',
        mixins: [fillMixin, componentsCore.mixins.skinBasedComp, backgroundCommon.mixins.backgroundDetectionMixin, componentsCore.mixins.createChildComponentMixin],
        propTypes: {
            style: santaComponents.santaTypesDefinitions.Component.style.isRequired,
            isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isPreviewMode: santaComponents.santaTypesDefinitions.isPreviewMode.isRequired,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth.isRequired,
            getScreenWidth: santaComponents.santaTypesDefinitions.getScreenWidth.isRequired,
            getScrollBarWidth: santaComponents.santaTypesDefinitions.getScrollBarWidth.isRequired,
            browser: santaComponents.santaTypesDefinitions.Browser.browser.isRequired,
            childrenData: santaComponents.santaTypesDefinitions.ColumnsContainer.childrenData.isRequired
        },
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: components.mediaCommon.mediaBehaviors.fill
        },


        componentDidLayout() {
            const scrollBarWidth = this.props.getScrollBarWidth();
            const screenWidth = this.props.getScreenWidth();
            const scrollBarChanged = this.lastScrollBarWidth !== scrollBarWidth;
            const screenWidthChanged = this.lastScreenWidth !== screenWidth;
            const isMobile = this.props.isMobileView;
            if (!isMobile && (scrollBarChanged || screenWidthChanged)) {
                this.lastScrollBarWidth = scrollBarWidth;
                this.lastScreenWidth = screenWidth;
                this.registerReLayout();
                this.forceUpdate();
            }
        },

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer';
        },

        getMobileSkinProperties() {
            let accumY = this.props.compProp.rowMargin;
            const siteWidth = this.props.siteWidth;
            const children = React.Children.map(this.props.children, function (child) {
                const rootStyle = {
                    position: 'absolute',
                    top: accumY,
                    height: this.props.childrenData[child.props.id].height,
                    left: 0,
                    width: siteWidth
                };

                accumY += rootStyle.height + this.props.compProp.columnsMargin;

                const mediaDimensions = {
                    width: siteWidth,
                    height: this.props.childrenData[child.props.id].height
                };

                child = React.cloneElement(child, {
                    rootStyle,
                    mediaDimensions
                });
                return child;
            }, this);

            const stripContainerHeight = accumY - this.props.compProp.columnsMargin + this.props.compProp.rowMargin;
            return {
                '': _.assign({
                    tagName: 'section',
                    style: {
                        height: stripContainerHeight
                    }
                }, this.getDataAttributesForAnchorLayout()),
                background: this.createFillLayers({
                    mediaDimensions: {
                        width: siteWidth
                    }
                }),
                inlineContent: {
                    children
                }
            };
        },

        getDataAttributesForAnchorLayout() {
            return {
                'data-col-margin': this.props.compProp.columnsMargin,
                'data-row-margin': this.props.compProp.rowMargin
            };
        },

        isScreenWidth() {
            return this.props.compProp.fullWidth;
        },

        getDesktopRelativeSkinProperties() {
            const {siteWidth, childrenData, children, style, isMeshLayoutMechanism} = this.props;
            const {fullWidth, frameMargin, columnsMargin, siteMargin, rowMargin} = this.props.compProp;

            const columnCount = _.size(this.props.children);
            const minStripWidth = siteWidth - siteMargin * 2; // eslint-disable-line no-mixed-operators
            const minContentWidth = siteWidth - frameMargin * 2; // eslint-disable-line no-mixed-operators
            const totalContentWidth = minContentWidth - columnsMargin * (columnCount - 1); // eslint-disable-line no-mixed-operators
            const structureWidths = React.Children.map(children, ({props}) => childrenData[props.id].width);
            const columnsTotalStructureWidth = _.sum(structureWidths);
            const normalizedContentWidths = _.map(structureWidths, structureWidth => Math.round(structureWidth * totalContentWidth / columnsTotalStructureWidth));
            normalizedContentWidths[0] += totalContentWidth - _.sum(normalizedContentWidths);

            const stripWidth = fullWidth ? '100%' : siteWidth;
            const stripStyle = _.defaults({
                left: 0,
                width: stripWidth,
                minWidth: `${minStripWidth}px`
            }, isMeshLayoutMechanism && {height: 'auto'}, style);

            const newChildren = React.Children.map(children, (child, index) => {
                const childId = child.props.id;
                const contentWidth = normalizedContentWidths[index];
                const {alignment, height, width} = childrenData[childId];
                const contentArea = {width: contentWidth, alignment: alignment / 100};

                const rootStyle = {
                    position: 'relative',
                    width: '100%',
                    left: 0,
                    flex: contentWidth,
                    marginLeft: index ? `${columnsMargin}px` : 0,
                    minWidth: `${contentWidth}px`,
                    top: isMeshLayoutMechanism ? 0 : rowMargin,
                    marginTop: isMeshLayoutMechanism ? rowMargin : 0,
                    marginBottom: isMeshLayoutMechanism ? rowMargin : 0,
                    height: isMeshLayoutMechanism ? '' : height
                };
                const mediaDimensions = {
                    width,
                    height
                };
                child = React.cloneElement(child, {
                    rootStyle,
                    contentArea,
                    mediaDimensions
                });
                return child;
            });

            const stripBgStyle = {
                width: `calc(100% - ${siteMargin * 2}px)`,
                minWidth: siteWidth
            };

            const contentStyle = {
                position: 'relative',
                width: `calc(100% - ${(siteMargin + frameMargin) * 2}px)`,
                minWidth: `${minContentWidth}px`
            };

            const stripMarker = this.props.isPreviewMode ? this.createContentAreaMarker(stripWidth) : null;
            return {
                '': _.assign({tagName: 'section', style: stripStyle, 'data-responsive': 'true'},
                    this.isScreenWidth() && {'data-is-screen-width': true}, this.getDataAttributesForAnchorLayout()),
                background: this.createFillLayers({
                    bgStyle: stripBgStyle,
                    mediaDimensions: {width: siteWidth}
                }),
                inlineContent: {
                    style: contentStyle,
                    children: [stripMarker, ...newChildren]
                }
            };
        },

        createContentAreaMarker(width) {
            const areaMarkerReactStructure = santaCoreUtils.contentAreaUtil.getContentAreaMarkingElement({alignment: 0.5, width}, this.props.id);
            return React.createElement.apply(null, areaMarkerReactStructure);
        },

        getMobileSkinPropertiesForMesh() {
            const padding = this.props.compProp.rowMargin;
            const siteWidth = this.props.siteWidth;
            const children = React.Children.map(this.props.children, function (child, index) {
                const isLast = index === this.props.children.length - 1;
                const rootStyle = {
                    position: 'relative',
                    marginBottom: isLast ? 0 : this.props.compProp.columnsMargin
                };

                const inlineParentStyle = {
                    position: 'relative'
                };

                const inlineStyle = {
                    position: 'relative'
                };

                const containerStyle = {
                    position: 'relative'
                };

                const mediaDimensions = {
                    width: siteWidth,
                    height: this.props.childrenData[child.props.id].height
                };

                child = React.cloneElement(child, {
                    isMobileResponsive: true,
                    rootStyle,
                    inlineParentStyle,
                    inlineStyle,
                    mediaDimensions,
                    containerStyle
                });
                return child;
            }, this);

            const contentStyle = {
                position: 'relative',
                padding: `${padding}px 0`
            };

            return {
                '': {
                    tagName: 'section',
                    'data-mobile-responsive': 'true',
                    style: {
                        height: 'auto'
                    }
                },
                background: this.createFillLayers({
                    mediaDimensions: {
                        width: siteWidth
                    }
                }),
                inlineContent: {
                    style: contentStyle,
                    children
                }
            };
        },

        getSkinProperties() {
            if (this.props.isMobileView) {
                return this.props.isMeshLayoutMechanism ?
                    this.getMobileSkinPropertiesForMesh() :
                    this.getMobileSkinProperties();
            }

            return this.getDesktopRelativeSkinProperties();
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.StripColumnsContainer', stripColumnsContainer);

    return stripColumnsContainer;
});
